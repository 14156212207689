// Package Imports
import React, { useEffect, useState, useContext } from "react";
import axios from "axios";

// CSS Imports
import classNames from "./signInPage.module.scss";
import "./mobile.scss";
import "./login.scss";


// Context Imports
import { GlobalContex } from "../../globalContex";

// Image Imports

//cPage/Component Imports
import LoadingAnimation from "../../globalComponents/LoadingAnimation";
import GlobalSidebar from "../../globalComponents/GlobalSidebar";
import appFullLogo from "../../static/images/mainIcon.svg";
import newAppLogo from "../../static/images/templateIcons/mainlogo.png"

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Password from "antd/lib/input/Password";
import { useNavigate } from "react-router-dom";

const SignInPage = () => {
  const {
    selectedApp,
    selectedBrandApp,
    setSelectedApp,
    loginData,
    setLoginData,
    globalMenu,
    setBankerEmail,
  } = useContext(GlobalContex);
  const navigate = useNavigate();
  const [focused, setFocused] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [Confirmpassword, setConfirmPassword] = useState("");
  const [twoFaPin, setTwoFaPin] = useState("");
  const [footerLoading, setFooterLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState("");
  const [message, setMessage] = useState("");
  const [resetDetails, setResetDetails] = useState("");
  const [numCode, setNumCode] = useState("");

  const [appli, setAppli] = useState();

  var OperID = JSON.parse(localStorage.getItem("opeID")) || "";
  console.log(OperID, "checking checking checking dynamic operatorsID data...");

  const registerUserToApp = () => {
    axios
      .post(`https://comms.globalxchange.io/gxb/apps/register/user`, {
        email: email,
        app_code: "mycryptobrand",
        fromAppCreation: true,
      })
      .then((res) => {
        if (res.data.message === "success") {
          console.log("User Registered To App", "statusUpdate");
        } else {
          console.log("User Already Registered To App", "statusUpdate");
        }
      });
  };

  const registerEmailForEntpreneur = () => {
    axios
      .post(`https://comms.globalxchange.io/gxb/app/gxlive/user/signup`, {
        email: email,
      })
      .then((res) => {
        if (res.data.message === "Successfully Signed Up") {
          console.log("Email Register For Entpreneur", "statusUpdate");
        } else {
          console.log(
            "Email Already Registered For Entpreneur",
            "statusUpdate"
          );
        }
      });
  };

  const loginbtn = () => {
    if (password === Confirmpassword) {
      let body = { newPassword: password, ...resetDetails };
      setLoading(true);
      axios
        .post(
          `https://gxauth.apimachine.com/gx/user/auth/login/challenge`,
          body
        )
        .then((res) => {
          if (res.data.status) {
            registerUserToApp();
            registerEmailForEntpreneur();
            setLoading(false);
            setLoginData(res.data);
            localStorage.setItem("bankerEmailNew", res?.data?.user.email);
            localStorage.setItem("TokenId", res?.data?.idToken);
            localStorage.setItem("loginData", JSON.stringify(res.data));
            setBankerEmail(res?.data?.user?.email);
          } else {
            setLoading(false);
            toast.info(res.data.message);
          }
        });
    } else {
      setPassword("");
      setConfirmPassword("");
      toast.info("Passwords do not match");
    }
  };

  const handleLogin = () => {
    if (
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
        email
      )
    ) {
      if (!password) {
        toast.info("Please Enter Password !!!");
        setMessage("Please Enter Password !!!");
      } else {
        setLoading(true);
        axios
          .post(`https://gxauth.apimachine.com/gx/user/auth/login`, {
            email: email,
            password: password,
          })
          .then((res) => {
            if (res.data.status) {
              registerUserToApp();
              registerEmailForEntpreneur();
              setLoading(false);
              setLoginData(res.data);
              localStorage.setItem("bankerEmailNew", res?.data?.user.email);
              localStorage.setItem("TokenId", res?.data?.idToken);
              localStorage.setItem("loginData", JSON.stringify(res.data));
              setBankerEmail(res?.data?.user?.email);
              // console.log(res?.data?.user?.email, "strting kahduyajgdhasd login  emial checking...");
            } else if (
              res.data.challengeName === "NEW_PASSWORD_REQUIRED" &&
              res.data.resetPassword &&
              res.data.message === "Reset User Password!"
            ) {
              setLoading(false);
              setStep("Force Reset Password");
              setMessage(
                "For Security Reasons You Need To Change Your Password"
              );
              setPassword("");
              setResetDetails({
                email: res.data.email,
                username: res.data.username,
                authChallenge_id: res.data.authChallenge_id,
                challengeName: res.data.challengeName,
                session: res.data.session,
              });
            } else if (
              res.data.challengeName === "SOFTWARE_TOKEN_MFA" &&
              res.data.mfa &&
              res.data.message === "Enter the Authenticator Code!"
            ) {
              setLoading(false);
              setStep("2FA auth");
              setMessage("Please Enter Your 2 Factor Authentication");
              setResetDetails({
                email: res.data.email,
                username: res.data.username,
                authChallenge_id: res.data.authChallenge_id,
                challengeName: res.data.challengeName,
                session: res.data.session,
              });
            } else {
              setLoading(false);
              setStep("");
              toast.info(res.data.message);
              setMessage(
                "You Have Entered Incorrect Login Credentials. Please Try Again"
              );
            }
          });
      }
    } else {
      toast.info("Enter Valid Email");
      setMessage("Enter Valid Email");
    }
    //Register User For App

    //Register Email For Entpreneur

    // console.log(email, "Mugil is testing the email...");
  };

  const mfalogin = () => {
    let body = { totp_code: numCode, ...resetDetails };
    setLoading(true);
    axios
      .post(`https://gxauth.apimachine.com/gx/user/auth/login/challenge`, body)
      .then((res) => {
        if (res.data.status) {
          registerUserToApp();
          registerEmailForEntpreneur();
          setLoading(false);
          setLoginData(res.data);
          localStorage.setItem("bankerEmailNew", res?.data?.user.email);
          localStorage.setItem("TokenId", res?.data?.idToken);
          localStorage.setItem("loginData", JSON.stringify(res.data));
          setBankerEmail(res?.data?.user?.email);
        } else {
          setLoading(false);
          toast.info(res.data.message);
        }
      });
    // console.log("mfa-login " , body)
  };

  const frgtstep = () => {
    setStep("Forgot Password");
    setMessage("");
  };

  const verifybtn = () => {
    let body = {
      email: email,
      app_code: selectedApp?.appName,
    };
    setLoading(true);
    axios
      .post(
        `https://gxauth.apimachine.com/gx/user/password/forgot/request`,
        body
      )
      .then((res) => {
        if (res.data.status) {
          setLoading(false);
          setStep("ConfirmCode");
          setMessage(`Please Enter The 6 Digit Code Sent To ${email}`);
          setPassword("");
        } else {
          setLoading(false);
          toast.info(res.data.message);
        }
      });
    // console.log("Verifybtn ", body)
  };

  const passwordchange = () => {
    if (password === Confirmpassword) {
      let body = {
        email: email,
        code: numCode,
        newPassword: password,
      };
      setLoading(true);
      axios
        .post(
          `https://gxauth.apimachine.com/gx/user/password/forgot/confirm`,
          body
        )
        .then((res) => {
          if (res.data.status) {
            setLoading(false);
            toast.success(res.data.message);
            setEmail("");
            setPassword("");
            setNumCode("");
            setMessage("");
            setStep("default");
          } else {
            setLoading(false);
            toast.info(res.data.message);
          }
        });
    } else {
      toast.info("Passwords do not match");
    }
  };

  const conditionalSteps = () => {
    switch (step) {
      case "Force Reset Password":
        return (
          <>
            <br />
            <p>{message}</p>
            <div style={{ paddingTop: "50px", paddingBottom: "60px" }}>
              <div style={{ paddingTop: "50px" }}>
                <input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="inputLogin"
                  type="password"
                  placeholder="Password"
                />
              </div>
            </div>
            <div
              onClick={() => (
                setStep("Confirm Password"),
                setMessage("Please Confirm The Password You Just Entered")
              )}
              className="btnLogin"
              style={{ background: selectedApp?.appColor }}
            >
              Next
            </div>
          </>
        );
      case "Confirm Password":
        return (
          <>
            <br />
            <p>{message}</p>
            <div style={{ paddingTop: "50px", paddingBottom: "60px" }}>
              <div style={{ paddingTop: "50px" }}>
                <input
                  value={Confirmpassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="inputLogin"
                  type="password"
                  placeholder="Password"
                />
              </div>
            </div>
            <div
              onClick={loginbtn}
              className="btnLogin"
              style={{ background: selectedApp?.appColor }}
            >
              Change Password
            </div>
          </>
        );
      case "Enter New Password":
        return (
          <>
            <p>{message}</p>
            <div style={{ paddingTop: "50px", paddingBottom: "60px" }}>
              <div style={{ paddingTop: "50px" }}>
                <input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="inputLogin"
                  type="password"
                  placeholder="Password"
                />
              </div>
            </div>
            <div
              onClick={() => (
                setStep("Enter Confirm Password"),
                setMessage("Please Confirm The Password You Just Entered")
              )}
              className="btnLogin"
              style={{ background: selectedApp?.appColor }}
            >
              Next
            </div>
            <p className="forgotbtn">
              Go Back To{" "}
              <span className="clickbtn" onClick={() => setStep("default")}>
                Login
              </span>
            </p>
          </>
        );
      case "Enter Confirm Password":
        return (
          <>
            <p>{message}</p>
            <div style={{ paddingTop: "50px", paddingBottom: "60px" }}>
              <div style={{ paddingTop: "50px" }}>
                <input
                  value={Confirmpassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="inputLogin"
                  type="password"
                  placeholder="Confirm Password"
                />
              </div>
            </div>
            <div
              onClick={passwordchange}
              className="btnLogin"
              style={{ background: selectedApp?.appColor }}
            >
              Change Password
            </div>
            <p className="forgotbtn">
              Go Back To{" "}
              <span className="clickbtn" onClick={() => setStep("default")}>
                Login
              </span>
            </p>
          </>
        );
      case "ConfirmCode":
        return (
          <>
            <br />
            <p>{message}</p>
            <div style={{ paddingTop: "50px", paddingBottom: "60px" }}>
              <div style={{ paddingTop: "50px" }}>
                <input
                  value={numCode}
                  onChange={(e) => setNumCode(e.target.value)}
                  className="inputLogin"
                  type="number"
                  placeholder="Six Digit Code"
                />
              </div>
            </div>
            <div
              onClick={() => setStep("Enter New Password")}
              className="btnLogin"
              style={{ background: selectedApp?.appColor }}
            >
              Next
            </div>
            <p className="forgotbtn">
              Go Back To{" "}
              <span className="clickbtn" onClick={() => setStep("default")}>
                Login
              </span>
            </p>
          </>
        );
      case "2FA auth":
        return (
          <>
            <br />
            <p>{message}</p>
            <div style={{ paddingTop: "50px", paddingBottom: "60px" }}>
              <div style={{ paddingTop: "50px" }}>
                <input
                  value={numCode}
                  onChange={(e) => setNumCode(e.target.value)}
                  className="inputLogin"
                  type="number"
                  placeholder="Six Digit Code"
                />
              </div>
            </div>
            <div
              onClick={mfalogin}
              className="btnLogin"
              style={{ background: selectedApp?.appColor }}
            >
              Next
            </div>
          </>
        );
      case "Forgot Password":
        return (
          <>
            <p>{message}</p>
            <div style={{ paddingTop: "50px", paddingBottom: "60px" }}>
              <div style={{ paddingTop: "50px" }}>
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="inputLogin"
                  type="text"
                  placeholder="Email"
                />
              </div>
            </div>
            <div
              className="btnLogin"
              onClick={verifybtn}
              style={{ background: selectedApp?.appColor }}
            >
              Verify Email
            </div>
            <p className="forgotbtn">
              Go Back To{" "}
              <span className="clickbtn" onClick={() => setStep("default")}>
                Login
              </span>
            </p>
          </>
        );
      default:
        return (
          <>
            <p>{message}</p>
            <div style={{ paddingTop: "20px" }}>
              <div>
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="inputLogin"
                  type="text"
                  placeholder="Email"
                />
              </div>
              <div style={{ paddingTop: "20px" }}>
                <input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="inputLogin"
                  type="password"
                  placeholder="Password"
                />
              </div>
            </div>
            <p className="forgotbtn">
              <span className="clickbtn">
                Forgot Password
              </span>
            </p>
            <div
              onClick={handleLogin}
              className="btnLogin"
            >
              Login
            </div>
            <div 
              onClick={() => window.open("/register", "_blank")}
              className="btnLogin" style={{ background: "white", color:"#000", marginTop: "20px" }}
            >
              Get Started
            </div>

          </>
        );
    }
  };

  return (
    <>
      <div className="desktopWrapper" style={{ display: "flex" }}>
        <GlobalSidebar globalMenu={globalMenu} />
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "0.8fr 1.2fr",
            width: "100%",
            height: "100vh",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              margin: "auto",
              alignItems: "center",

            }}
          >
            {loading ? (
              <LoadingAnimation logoAnim />
            ) : (
              <>
                <div style={{ width: "100%", alignItems: "center", display: "flex", flexDirection: "column", height: "100vh", justifyContent: "center" }}>
                  <div>
                    <img
                      style={{ width: "100%" }}
                      src={newAppLogo}
                      alt=""
                      className={classNames.logo}
                    />
                  </div>
                  <br/>
                  {conditionalSteps()}
                </div>
              </>
            )}
          </div>

          <div
            className="heroText"
            style={{
              // background: selectedApp?.appColor,
              color: "white",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ display: "none" }}>
              Don't Have A<br /> {selectedApp?.appName}
              <br /> Account?
              <div
                className="clickButton"
                style={{ marginTop: "70px" }}
                onClick={(e) => {
                  navigate("/register");
                }}
              >
                Click Here
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="mobileWrapper"
        style={{
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        {/* <div style={{ position: "absolute", top: 0 }}>
          <div>
            <div
              style={{
                minHeight: loginData
                  ? window.innerHeight - 110
                  : window.innerHeight,
                overflowY: "scroll",
                display: "flex",
                width: "100vw",
                justifyContent: "space-between",
              }}
            >
              {globalMenu.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="globalItem"
                    style={{
                      opacity: selectedApp?.appName === item.appName ? 1 : 0.3,
                    }}
                    onClick={(e) => {
                      if (item.name === "MyCryptoBrand") {
                        setSelectedApp(item);
                        // navigate(`/${item.appName}`);
                      }
                    }}
                  >
                    <img src={item?.appLogo} alt="" style={{ width: "30px" }} />
                  </div>
                );
              })}
            </div>
          </div>
        </div> */}
        {loading ? (
          <LoadingAnimation logoAnim mobile={true} />
        ) : (
          <>
            <div style={{ width: "70%" }}>
              <div style={{ width: "322px" }}>
                <img
                  style={{ width: "100%" }}
                  src={selectedApp?.appFullLogo}
                  alt=""
                  className={classNames.logo}
                />
              </div>
              <div style={{ paddingTop: "50px", paddingBottom: "60px" }}>
                <div>
                  <input
                    autoComplete="off"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="inputLogin"
                    type="text"
                    placeholder="Email"
                  />
                </div>
                <div style={{ paddingTop: "50px" }}>
                  <input
                    autoComplete="off"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="inputLogin"
                    type="password"
                    placeholder="Password"
                  />
                </div>
              </div>
            </div>
            <div
              onClick={handleLogin}
              className="btnLogin"
              style={{ background: selectedApp?.appColor, width: "70%" }}
            >
              Login
            </div>
          </>
        )}
      </div>
      <ToastContainer />
    </>
  );
};

export default SignInPage;

// if (res.data.operators.length == 0) {
//   localStorage.setItem(
//     "selectedsingleapplication",
//     JSON.stringify({
//       app_code: "",
//       app_icon: "",
//       app_name: "",
//       created_by: "",
//       date: "",
//       timestamp: "",
//       users_count: "",
//       _id: "",
//     })
//   );
// } else {
//   setAppli(res.data.operators[0].app_data[0]);
//   localStorage.setItem(
//     "selectedsingleapplication",
//     JSON.stringify(res.data.operators[0].app_data[0])
//   );
// }
